import _ from 'lodash-es'
/* eslint-disable no-unused-expressions */
import {cancelled, put} from 'redux-saga/es/effects.js'
import {isNetworkError} from '@lookout/request'
import {hasFetchSubactions} from '../store/fetch-subactions-helper.js'
import asyncOperation from './async-operation.js'

/*
 * Merges an array of fetch states into one fetch state. It uses the following
 * merging strategy. If there is at least on REQUEST then result is in REQUEST.
 * If not, then see if there is at least one in ERROR then result is in ERROR.
 * Otherwise we call it SUCCESS.
 */
export const mergeFetchStates = states =>
  _.thru(
    _.reduce(
      _.flattenDeep(_.castArray(states)),
      (result, fetchState) =>
        // eslint-disable-next-line no-param-reassign
        _.tap(result, counts => (counts[fetchState?.in || 'NONE'] += 1)),
      {REQUEST: 0, SUCCESS: 0, ERROR: 0, NONE: 0},
    ),
    counts =>
      counts.REQUEST > 0
        ? {in: 'REQUEST'}
        : counts.ERROR > 0
          ? {in: 'ERROR'}
          : counts.SUCCESS > 0
            ? {in: 'SUCCESS'}
            : undefined,
  )

const isInState = (arg, inState) =>
  _.thru(mergeFetchStates(arg), fetchState => fetchState?.in === inState)

export const isUndefined = (...args) => isInState(args, undefined)
export const isFetching = (...args) => isInState(args, 'REQUEST')
export const isSuccess = (...args) => isInState(args, 'SUCCESS')
export const isError = (...args) => isInState(args, 'ERROR')

/*
 * A helper to convert fetch state into a legacy Chaplin SyncState. This is in
 * case if we want to convert a fetch state into a Backbone Model or
 * Collection syncState.
 */
export const toSyncState = fetchState =>
  fetchState
    ? isFetching(fetchState)
      ? 'syncing'
      : isSuccess(fetchState)
        ? 'synced'
        : 'unsynced'
    : 'unsynced'

/*
 * A helper to convert fetch state into a legacy Chaplin.SyncMachine method.
 */
export const toSyncStateMethod = fetchState =>
  fetchState
    ? isFetching(fetchState)
      ? 'beginSync'
      : isSuccess(fetchState)
        ? 'finishSync'
        : 'unsync'
    : 'unsync'

/*
 * A helper to convert a legacy Chaplin SyncState into a fetch state. This is in
 * case if we want to convert Backbone Model or Collection syncState into
 * a fetch state.
 */
export const fromSyncState = syncState => ({
  in:
    syncState === 'syncing'
      ? 'REQUEST'
      : syncState === 'synced'
        ? 'SUCCESS'
        : 'ERROR',
})

export const fromBooleanState = fetchState =>
  fetchState?.isError
    ? {in: 'ERROR'}
    : fetchState?.isFetching
      ? {in: 'REQUEST'}
      : fetchState && {in: 'SUCCESS'}

/*
 * An async helper that calls a request lib func and callbacks fetch state
 * updates.
 */
export async function fetch(
  request,
  url,
  {fetchStateCallback, isAsyncOperation, asyncOptions, signal, ...options} = {},
) {
  if (!_.isFunction(request))
    throw new Error("'request' argument should be a function.")
  if (!_.isString(url))
    throw new Error("'url' argument should be an URL string.")
  fetchStateCallback?.({in: 'REQUEST'})
  let response
  try {
    response = await request(url, {signal, ...options})
    if (isAsyncOperation) {
      ;({data: response} = await asyncOperation(request, response, {
        signal,
        ...asyncOptions,
      }))
    }
    fetchStateCallback?.({in: 'SUCCESS'})
  } catch (error) {
    if (isNetworkError(error)) fetchStateCallback?.({in: 'ERROR'})
    throw error
  }
  return response
}

/*
  Returns a function to pass as a fetchStateCallback argument into each
  of multiple fetch calls of a service function. This multi callback function
  will make sure the external caller of the service function will get updated
  with proper array of fetch states.
 */
export const multiCallback = (fetchStates, fetchStateCallback) => {
  if (!_.isObject(fetchStates))
    throw new TypeError("'fetchStates' must be object")
  if (!_.isFunction(fetchStateCallback)) return
  const key = _.uniqueId('fetchState-')
  return fetchState => {
    // eslint-disable-next-line no-param-reassign
    fetchStates[key] = fetchState
    fetchStateCallback(_.values(fetchStates))
  }
}

/*
 * A helper that can analyze an error that raised during a fetch request.
 * It will dispatch a standard redux fetch ERROR subaction if the error is
 * caused by any network activity. Otherwise it will just rethrow an error.
 */
export function* handleErrorSaga(
  error,
  {subactionCreator, actionType, meta, errorCallback} = {},
) {
  if (isNetworkError(error)) {
    _.defaults(error, {response: {status: 0}}) // viva jQuery AJAX convention
    if (subactionCreator || actionType) {
      yield put(
        subactionCreator
          ? subactionCreator(error, meta)
          : _.extend(actionType, {payload: error, meta, error: true}),
      )
    }
    yield errorCallback?.(error)
  } else {
    throw error
  }
}

/*
 * A saga helper that calls a request lib func and dispatches standard redux
 * fetch subactions (SUCCESS or ERROR). It will make sure that the network
 * request is cancelled if parent saga is cancelled as well. It could be used
 * for most fetch operations within a redux-saga where a simple payload
 * is being sent to backend.
 */
export function* fetchSaga(fetchFuncOrRequest, configOrUrl, config) {
  if (!_.isFunction(fetchFuncOrRequest))
    throw new Error("'fetchFuncOrRequest' argument should be a function.")

  let fetchFunc
  let request
  let url
  if (_.isString(configOrUrl)) {
    // it is URL
    request = fetchFuncOrRequest
    url = configOrUrl
  } else {
    // it is a config object
    fetchFunc = fetchFuncOrRequest
    // eslint-disable-next-line no-param-reassign
    config = configOrUrl
  }

  const {
    options = {},
    subactionCreators,
    payloadCreator,
    meta,
    successCallback,
    errorCallback,
  } = config

  if (subactionCreators && !hasFetchSubactions(subactionCreators))
    throw new Error(
      "'subactionCreators' in config should have standard fetch subactions.",
    )
  if (payloadCreator && !_.isFunction(payloadCreator))
    throw new Error("'payloadCreator' in config should be a function.")

  // Setup abortion only if it is not set by the outside context
  let abortController
  if (!options.signal) {
    abortController = new globalThis.AbortController()
    options.signal = abortController.signal
  }

  let response
  try {
    if (fetchFunc) response = yield fetchFunc(options)
    else response = yield request(url, options)
    const payload = payloadCreator ? payloadCreator(response) : response
    if (subactionCreators) {
      yield put(subactionCreators.success(payload, meta))
    }
    yield successCallback?.(payload)
  } catch (error) {
    yield handleErrorSaga(error, {
      subactionCreator: subactionCreators.error,
      meta,
      errorCallback,
    })
  } finally {
    if (yield cancelled()) abortController?.abort()
  }

  // TODO: remove this after DONE and other legacy subactions are retired
  yield put(subactionCreators.done())
  return response
}
