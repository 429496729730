import _ from 'lodash-es'
import reduceReducers from 'reduce-reducers'
import {handleActions} from 'redux-actions'
import {merge, RiskLevelName} from '../../../lib/utils.js'
import createSelector from '../../../lib/create-selector.js'
import {getFetchReducerMap} from '../../../store/fetch-subactions-helper.js'
import {Source} from '../gds-param-types.js'
import {READ_TOP_ISSUES} from '../../../store/gds-actions.js'
import {READ_POLICIES} from '../../../store/l4e-actions.js'
import {isError} from '../../../services/fetch-helper.js'
import {removeAnnotations} from '../../policies/policies-utils.js'
import {getUIBindings} from './issue-trends-ui-reducer.js'
import createIssueTrendsFetchStateReducer from './create-issue-trends-fetch-state-reducer.js'

export const initialState = {
  byDateRange: null,
  globalReadState: null,
  fleetReadState: null,
  policyRiskMap: null,
  policiesReadState: null,
}

const reducer = handleActions(
  {
    [READ_TOP_ISSUES.SUCCESS]: (state, {payload}) =>
      merge({}, state, {
        byDateRange: {
          [payload.dateRange]: {
            [payload.source]: {
              topIssues: payload.topIssues,
            },
          },
        },
      }),

    [READ_POLICIES.SUCCESS]: (state, {payload}) =>
      merge({}, state, {
        policyRiskMap: _.reduce(
          payload.policies,
          (acc, policy) => {
            const {classification, severity} = removeAnnotations(policy)
            return {
              ...acc,
              [_.snakeCase(_.lowerCase(classification))]:
                RiskLevelName[severity]?.toLowerCase(),
            }
          },
          {},
        ),
      }),
  },
  initialState,
)

const policiesFetchStateReducer = handleActions(
  {
    ...getFetchReducerMap(READ_POLICIES, (state, policiesReadState) =>
      merge({}, state, {policiesReadState}),
    ),
  },
  initialState,
)

export const getTopIssues = state => state.issueTrends.topIssues

export const createGetTopIssuesByDateRange = () =>
  createSelector(
    [getTopIssues, getUIBindings],
    (topIssues, ui) => topIssues?.byDateRange?.[ui.dateRangeSelection],
  )

export const createGetIsAnalyzingFleetTopIssuesData = () =>
  createSelector(getTopIssues, ({fleetReadState}) => isError(fleetReadState))

export const createGetTopIssuesBySource = () =>
  createSelector(
    [
      createGetTopIssuesByDateRange(),
      createGetIsAnalyzingFleetTopIssuesData(),
      getUIBindings,
    ],
    (topIssues, isAnalyzingFleetData, ui) =>
      _.thru(
        ui.sourceSelection === Source.GLOBAL || isAnalyzingFleetData
          ? Source.GLOBAL
          : Source.FLEET,
        source => topIssues?.[source]?.topIssues,
      ),
  )

export const createGetPolicyRiskMap = () =>
  createSelector(getTopIssues, topIssues => topIssues.policyRiskMap)

export default reduceReducers(
  reducer,
  createIssueTrendsFetchStateReducer({
    actionType: READ_TOP_ISSUES,
    initialState,
  }),
  policiesFetchStateReducer,
)
