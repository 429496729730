import {combineReducers} from 'redux'
import appDetailsReducer from './app-details-reducer.js'
import appPropertiesReducer from './app-properties-reducer.js'
import appUrlSubmissionReducer from './app-url-submission-reducer.js'
import riskExposureReducer from './risk-exposure-reducer.js'
import violationsReducer from './violations-reducer.js'

const reducer = combineReducers({
  appDetails: appDetailsReducer,
  appProperties: appPropertiesReducer,
  appSubmission: appUrlSubmissionReducer,
  riskExposure: riskExposureReducer,
  violations: violationsReducer,
})

export default reducer
