import {urlJoin} from '../lib/utils.js'
import lkt from '../lkt.js'

/**
 * Combines L4E service API url.
 * @param {String} path
 * @param {Object} options
 */
export const apiUrlFor = (path, {ver = 1} = {}) =>
  urlJoin(lkt.config.api.l4e, `/api/public/v${ver}`, path)

/**
 * Combines Auth service API url.
 * @param {String} path
 * @param {Object} options
 */
export const authApiUrlFor = (path, {ver = 1} = {}) =>
  urlJoin(lkt.config.api.auth, `/v${ver}`, path)

/**
 * Combines Evergreen service API url.
 */
export const mdmApiUrlFor = path => urlJoin(lkt.config.api.mdm, '/v1', path)

/**
 * Combines ARGOS service API url.
 */
export const argosApiUrlFor = (path, {ver} = {}) =>
  urlJoin(lkt.config.api.argos, `/argos/v${ver === 3 ? ver : 2}`, path)

/**
 * Combines Content Submission service API url.
 */
export const contentSubmissionApiUrlFor = (path, {ver = 0} = {}) =>
  urlJoin(lkt.config.api.content_submission, ver !== 0 && `/api/v${ver}`, path)

/**
 * Combines TIQS service API url.
 */
export const tiqsApiUrlFor = path => urlJoin(lkt.config.api.tiqs, path)

/**
 * Combines Global Data service API url.
 */
export const gdsApiUrlFor = path => urlJoin(lkt.config.api.gds, path)

/**
 * Combines Policy Store service API url.
 */
export const policyStoreApiUrlFor = path =>
  urlJoin(lkt.config.api.policy_store, path)

/**
 * Combines Acquisition service API url.
 */
export const acquisitionApiUrlFor = path =>
  urlJoin(lkt.config.api.acquisition, path)

/**
 * Combines Pushcart service API url.
 */
export const pushcartApiUrlFor = path => urlJoin(lkt.config.api.pushcart, path)

export const assistantApiUrlFor = path =>
  urlJoin(lkt.config.api.assistant, path)

export const newsApiUrlFor = path => urlJoin(lkt.config.api.news, path)
