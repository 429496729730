import _ from 'lodash'
import {combineReducers} from 'redux'
import {createResponsiveStateReducer} from 'redux-responsive'
import {breakpoints} from '@lookout/design'
import appsReducer from '../components/apps/apps-reducer.js'
import dashboardReducers from '../components/dashboard/dashboard-reducers.js'

const {screenXxsMax, screenXsMax, screenSmMax, screenMdMax, screenLgMax} =
  breakpoints

const maxima = {
  screenXxsMax,
  screenXsMax,
  screenSmMax,
  screenMdMax,
  screenLgMax,
}

const resettableReducer =
  (rootReducer, excludedKeys = []) =>
  (state, action) =>
    action.type === 'store/reset'
      ? rootReducer(
          _.mapValues(state, (value, key) =>
            _.includes(excludedKeys, key) ? value : undefined,
          ),
          action,
        )
      : rootReducer(state, action)

const rootReducer = combineReducers({
  browser: createResponsiveStateReducer(maxima),
  apps: appsReducer,
  ...dashboardReducers,
})

const storeReducer = resettableReducer(rootReducer, ['browser'])

export default storeReducer
