import {combineReducers} from 'redux'
import multiseries from './issue-trends-multiseries-reducer.js'
import topIssues from './issue-trends-top-issues-reducer.js'
import ui from './issue-trends-ui-reducer.js'

const reducer = combineReducers({
  multiseries,
  topIssues,
  ui,
})

export default reducer
