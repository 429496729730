import {defineAction} from 'redux-define'
import {createAction} from 'redux-actions'
import {defineFetchSubactions} from './fetch-subactions-helper.js'

const argos = defineAction('argos')

export const FETCH_CATEGORIES = defineFetchSubactions(
  argos.defineAction('categories'),
)

export const fetchCategories = createAction(FETCH_CATEGORIES.REQUEST)

export const FETCH_SUBCATEGORIES = defineFetchSubactions(
  argos.defineAction('subcategories'),
)

export const fetchSubcategories = createAction(FETCH_SUBCATEGORIES.REQUEST)

export const FETCH_FLEET_APP_COUNT = defineFetchSubactions(
  argos.defineAction('fleet-app-count'),
)

export const fetchFleetAppCount = createAction(FETCH_FLEET_APP_COUNT.REQUEST)

export const FETCH_APP_DETAILS = defineFetchSubactions(
  argos.defineAction('app-details'),
)

export const fetchAppDetails = createAction(FETCH_APP_DETAILS.REQUEST)

export const FETCH_MALWARE_FAMILIES = defineFetchSubactions(
  argos.defineAction('malware-families'),
)

export const fetchMalwareFamilies = createAction(FETCH_MALWARE_FAMILIES.REQUEST)

export const FETCH_VIOLATIONS = defineFetchSubactions(
  argos.defineAction('violations'),
)

export const fetchViolations = createAction(FETCH_VIOLATIONS.REQUEST)

export const FETCH_NETWORK_ACTIVITY = defineFetchSubactions(
  argos.defineAction('network-activity'),
)

export const fetchNetworkActivity = createAction(FETCH_NETWORK_ACTIVITY.REQUEST)
