import _ from 'lodash-es'
import React, {useCallback, useState} from 'react'
import {Button, ModalDialog, MODAL_Z_INDEX} from '@lookout/ui'
import I18n from '../i18n.js'
import {renderModal} from '../components/base/top-level-modal.js'
import {hasChanges} from './change-tracker.js'
import {classes} from './utils.js'

const ConfirmButton = props => <Button secondary {...props} />

const ConfirmModalDialog = ({className, onClosed}) => (
  <ModalDialog
    modalClassName={classes('confirm-navigation-modal', className)}
    title={I18n.t('navigation.confirm_title')}
    text={I18n.t('navigation.confirm_continue')}
    confirmButton={ConfirmButton}
    confirmText={I18n.t('buttons.ok')}
    cancelText={I18n.t('buttons.cancel')}
    onClosed={onClosed}
  />
)

export const useConfirmNavigation = key => {
  const [modalProps, setModalProps] = useState()
  const show = useCallback(
    props => {
      setModalProps(undefined)
      return hasChanges(key)
        ? new Promise(resolve =>
            setModalProps({
              ...props,
              onClosed: value => {
                setModalProps(undefined)
                resolve(value || false) // value is undefined if user clicks outside of the dialog
              },
            }),
          )
        : Promise.resolve(true)
    },
    [key],
  )
  const modalDialog = modalProps && <ConfirmModalDialog {...modalProps} />
  return [show, modalDialog]
}

/*
 * It will show in-app confirm dialog if there are unsaved changes in the
 * currently visible view. This is to be used in the legacy Backbone views or controllers.
 * @param  {Function} callback True if user agrees to abandon unsaved changes
 * and False otherwise.
 * @return {Boolean}           True if there are unsaved changes and confirm
 * dialog was presented and False otherwise.
 */
const confirmNavigation = callback => {
  if (!hasChanges()) return false
  // using fake signal as it is not obvious how to abort during Backbone routing navigation
  const signal = {addEventListener: _.noop}
  renderModal('confirm-navigation-container', signal, unmount => (
    <ConfirmModalDialog
      css={{zIndex: MODAL_Z_INDEX + 10}} // to stay above other possible modals on pages
      onClosed={agreed => {
        unmount()
        callback(agreed)
      }}
    />
  ))
  return true
}

export default confirmNavigation
