// for various transition overrides and other disabling modes
// if cypress is running then globalThis.Cypress is set and if it is taking or
// matching snapshots mode the type param is set

let cypressCheck

///////////////
//////////////////////////////////////////////////////
/////////
cypressCheck = () => false
//////////

export const isInCypressSnapshotTesting = cypressCheck
