import {combineReducers} from 'redux'
import protectedDevices from './protected-devices-reducer.js'
import analyzedApps from './analyzed-apps-reducer.js'

const reducer = combineReducers({
  protectedDevices,
  analyzedApps,
})

export default reducer
