var ErrorView;

import _ from 'lodash-es';

import ErrorLayout from '../../components/errors/error-layout';

import ReactContentView from '../base/react-content-view';

export default ErrorView = (function() {
  class ErrorView extends ReactContentView {
    initialize(options) {
      return super.initialize(_.extend(options, {
        className: this.className,
        component: ErrorLayout,
        props: {title: this.title, description: this.description, showLogout: this.showLogout, ...this.props}
      }));
    }

  };

  ErrorView.prototype.optionNames = ErrorView.prototype.optionNames.concat(['title', 'description', 'props', 'showLogout']);

  ErrorView.prototype.className = 'system-error-view';

  return ErrorView;

}).call(this);
