import globalProxy from './global-proxy.js'

export const getLocation = () => globalProxy().location

export const assignLocation = url => globalProxy().location.assign(url)

export const reloadLocation = () => globalProxy().location.reload()

export const replaceLocation = url => globalProxy().location.replace(url)

export const replaceState = (...args) =>
  globalProxy().history.replaceState(...args)
