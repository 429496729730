var History;

import _ from 'lodash-es';

import Chaplin from 'chaplin';

export default History = class History extends Chaplin.History {
  // Chaplin overrides Backbone.History.start, but forgot to do so for stop
  stop() {
    super.stop(...arguments);
    // eslint-disable-next-line coffee/no-underscore-dangle
    if (this._hasPushState) {
      return globalThis.window.addEventListener('popstate', this.checkUrl);
    // eslint-disable-next-line coffee/no-underscore-dangle
    } else if (this._wantsHashChange) {
      return globalThis.window.removeEventListener('hashchange', this.checkUrl);
    }
  }

};
