import _ from 'lodash-es'
import {createAction} from 'redux-actions'

export const createSubactions = (actionType, subactions) =>
  _.reduce(
    subactions,
    (obj, key) =>
      _.extend(obj, {
        [key.toLowerCase()]: createAction(
          actionType[key],
          payload => payload,
          (payload, meta = {}) => meta,
        ),
      }),
    {},
  )

export const hasSubactions = (subactionCreators, subactions) =>
  _.every(
    subactions.map(a => a.toLowerCase()),
    a => _.isFunction(subactionCreators[a]),
  )
