import {defineAction} from 'redux-define'
import {createAction} from 'redux-actions'

const mes = defineAction('mes')
const dashboard = mes.defineAction('dashboard')
const issueTrends = dashboard.defineAction('issue-trends')

export const CANCEL_SAGA = issueTrends.defineAction('CANCEL_SAGA')
export const cancelSaga = createAction(CANCEL_SAGA.ACTION)

export const SET_DATE_RANGE = issueTrends.defineAction('SET_DATE_RANGE')
export const setDateRange = createAction(SET_DATE_RANGE.ACTION)

export const SET_SOURCE = issueTrends.defineAction('SET_SOURCE')
export const setSource = createAction(SET_SOURCE.ACTION)

export const SET_ISSUE_TYPE_FILTER = issueTrends.defineAction(
  'SET_ISSUE_TYPE_FILTER',
)
export const setIssueTypeFilter = createAction(SET_ISSUE_TYPE_FILTER.ACTION)

export const SET_SCRUB_INDEX = issueTrends.defineAction('SET_SCRUB_INDEX')
export const setScrubIndex = createAction(SET_SCRUB_INDEX.ACTION)
