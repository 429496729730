import _ from 'lodash-es'
import {handleActions} from 'redux-actions'
import I18n from '../../i18n.js'
import {FETCH_VIOLATIONS} from '../../store/argos-actions.js'

export const initialState = {
  notification: false,
  isFetching: false,
  isError: false,
  violationsByAppId: {},
}

const reducer = handleActions(
  {
    [FETCH_VIOLATIONS.REQUEST]: state => ({
      ...state,
      notification: null,
      isFetching: true,
      isError: false,
    }),

    [FETCH_VIOLATIONS.SUCCESS]: (state, {payload}) =>
      _.merge({}, state, {
        violationsByAppId: {
          [payload.appId]: payload.violations,
        },
      }),

    [FETCH_VIOLATIONS.ERROR]: state =>
      _.merge({}, state, {
        notification: {
          message: I18n.t('apps.error'),
          isError: true,
        },
        isError: true,
      }),

    [FETCH_VIOLATIONS.DONE]: state => ({
      ...state,
      isFetching: false,
    }),
  },
  initialState,
)

export const getFetchState = state =>
  _.pick(state.apps.violations, ['isFetching', 'isError'])

export const getViolationsNotification = state =>
  state.apps.violations.notification

export const getViolationsByAppId = (state, {appId}) =>
  state.apps.violations.violationsByAppId[appId] || []

export default reducer
