import _ from 'lodash-es'

let callbacks = {}

/**
 * ChangeTracker is a singleton that accepts callbacks from
 * different views and components. The callbacks should return
 * true if a view has unsaved changes or false otherwise.
 */
export const hasChanges = key =>
  key ? callbacks[key]?.() : _.some(_.values(callbacks), cb => cb())

export const initChangeTracker = () =>
  globalThis.addEventListener('beforeunload', e => {
    if (hasChanges()) {
      e.preventDefault()
      e.returnValue = false
    }
  })

export const addChangesCallback = (fn, key) => {
  callbacks[key || _.uniqueId('has-change-callback-')] = fn
}

export const removeChangesCallback = fn => {
  _.unset(
    callbacks,
    _.findKey(callbacks, f => f === fn),
  )
}

export const resetChanges = () => (callbacks = {})
