import {createStore, applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly.js'
import createSagaMiddleware from 'redux-saga'
import {responsiveStoreEnhancer} from 'redux-responsive'
import storeReducer from './store-reducer.js'

const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

const composeEnhancers = composeWithDevTools({
  trace: true,
})

const finalCreateStore = composeEnhancers(
  responsiveStoreEnhancer,
  applyMiddleware(...middleware),
)(createStore)

const configureStore = (state = {}) => ({
  ...finalCreateStore(storeReducer, state),
})

export const runSaga = (...args) => sagaMiddleware.run(...args)

export const resetRedux = () => ({type: 'store/reset'})

export default configureStore
