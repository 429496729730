import _ from 'lodash-es'
import {handleActions} from 'redux-actions'
import {merge} from '../../../lib/utils.js'
import {getFetchReducerMap} from '../../../store/fetch-subactions-helper.js'
import {Source} from '../gds-param-types.js'

const createIssueTrendsFetchStateReducer = ({actionType, initialState}) =>
  handleActions(
    {
      ...getFetchReducerMap(actionType, (state, readState, {payload, meta}) =>
        _.thru(payload?.source ? payload.source : meta?.source, source =>
          merge(
            {},
            state,
            source === Source.GLOBAL
              ? {globalReadState: readState}
              : {fleetReadState: readState},
          ),
        ),
      ),
    },
    initialState,
  )

export default createIssueTrendsFetchStateReducer
