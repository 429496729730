import _ from 'lodash-es'
import request from './request.js'
import {getEntGuid} from './tenancy-helper.js'

const requestWithEntGuid = (url, {entGuid, ...opts} = {}) => {
  // eslint-disable-next-line no-param-reassign
  if (!entGuid) entGuid = getEntGuid()
  if (!entGuid) throw new TypeError("'entGuid' is required.")
  return request(url, _.merge({headers: {'X-LOOKOUT-ENT-GUID': entGuid}}, opts))
}

export default requestWithEntGuid
