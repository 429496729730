import {defineAction} from 'redux-define'
import {createAction} from 'redux-actions'
import {defineFetchSubactions} from './fetch-subactions-helper.js'

const tiqs = defineAction('tiqs')

export const FETCH_RISK_EXPOSURE = defineFetchSubactions(
  tiqs.defineAction('risk-exposure'),
)

export const fetchRiskExposure = createAction(FETCH_RISK_EXPOSURE.REQUEST)
