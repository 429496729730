import deployment from './deployment/deployment-reducer.js'
import issueTrends from './issue-trends/issue-trends-reducer.js'
import securityCloud from './security-cloud/security-cloud-reducer.js'
import appAnalysis from './app-analysis/app-analysis-reducer.js'

export default {
  deployment,
  issueTrends,
  securityCloud,
  appAnalysis,
}
