import {defineAction} from 'redux-define'
import {
  defineFetchSubactions,
  createFetchSubactions,
} from './fetch-subactions-helper.js'

const l4e = defineAction('l4e')
const policies = l4e.defineAction('policies')

export const READ_POLICIES = defineFetchSubactions(
  policies.defineAction('read'),
)
export const readPolicies = createFetchSubactions(READ_POLICIES)

const deployment = l4e.defineAction('deployment')
const stats = deployment.defineAction('stats')
const activeIssueCounts = deployment.defineAction('active-issue-counts')

export const READ_DEPLOYMENT_STATS = defineFetchSubactions(
  stats.defineAction('read'),
)
export const readDeploymentStats = createFetchSubactions(READ_DEPLOYMENT_STATS)

export const READ_ACTIVE_ISSUE_COUNTS = defineFetchSubactions(
  activeIssueCounts.defineAction('read'),
)
export const readActiveIssueCounts = createFetchSubactions(
  READ_ACTIVE_ISSUE_COUNTS,
)
