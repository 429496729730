import {handleActions} from 'redux-actions'
import {IssueType} from '../gds-param-types.js'
import {merge} from '../../../lib/utils.js'
import {
  SET_DATE_RANGE,
  SET_SOURCE,
  SET_ISSUE_TYPE_FILTER,
  SET_SCRUB_INDEX,
} from './issue-trends-actions.js'

export const initialState = {
  bindings: {
    dateRangeSelection: null,
    sourceSelection: null,
    issueTypeFilters: IssueType.values.reduce(
      (acc, issueType) => ({
        ...acc,
        [issueType]: true,
      }),
      {},
    ),
  },
  scrubIndex: -1,
}

const reducer = handleActions(
  {
    [SET_DATE_RANGE.ACTION]: (state, {payload}) =>
      merge({}, state, {
        bindings: {
          dateRangeSelection: payload,
        },
      }),

    [SET_SOURCE.ACTION]: (state, {payload}) =>
      merge({}, state, {
        bindings: {
          sourceSelection: payload,
        },
      }),

    [SET_ISSUE_TYPE_FILTER.ACTION]: (state, {payload}) =>
      merge({}, state, {
        bindings: {
          issueTypeFilters: {
            [payload.issueType]: payload.value,
          },
        },
      }),

    [SET_SCRUB_INDEX.ACTION]: (state, {payload}) => ({
      ...state,
      scrubIndex: payload,
    }),
  },
  initialState,
)

export const getUIBindings = state => state.issueTrends.ui.bindings

export const getScrubIndex = state => state.issueTrends.ui.scrubIndex

export default reducer
