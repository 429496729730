import _ from 'lodash-es'
import defineParamType from '../../lib/define-param-type.js'
import {isOrgTenancy} from '../../services/tenancy-helper.js'

export const DateRange = defineParamType([
  'LAST_7_DAYS',
  'LAST_30_DAYS',
  'LAST_90_DAYS',
  'LAST_365_DAYS',
])

export const threatsDateRangeMap = {
  last_7_days: 'last_7_days',
  last_30_days: 'last_30_days',
  last_90_days: 'last_90_days',
  last_365_days: 'last_12_months',
}

export const OsType = defineParamType(['ALL', 'IOS', 'ANDROID'])

export const isDateRangeValid = dateRange =>
  _.includes(
    _.filter(DateRange.values, value =>
      isOrgTenancy() ? value !== DateRange.LAST_90_DAYS : value,
    ),
    dateRange,
  )

export const Source = defineParamType(['COMPARISON', 'FLEET', 'GLOBAL'])
export const isSourceValid = source => _.includes(Source.values, source)

const vectors = ['APP', 'DEVICE', 'NETWORK', 'WEB']

export const Vector = defineParamType(vectors)

export const IssueType = defineParamType(['ALL', ...vectors, 'SMISHING'])

export const Risk = defineParamType(['HIGH', 'MEDIUM', 'LOW'])

export const Popularity = defineParamType([
  'VERY_RARE',
  'RARE',
  'UNCOMMON',
  'COMMON',
  'VERY_COMMON',
  'EXTREMELY_COMMON',
])
