import {defineAction} from 'redux-define'
import {createAction} from 'redux-actions'

const mes = defineAction('mes')
const apps = mes.defineAction('apps')
const appSubmission = apps.defineAction('app-submission')

export const RESET_APP_SUBMISSION = appSubmission.defineAction(
  'RESET_APP_SUBMISSION',
)
export const resetAppSubmission = createAction(RESET_APP_SUBMISSION.ACTION)

export const UPDATE_STAGED_URL = appSubmission.defineAction('UPDATE_STAGED_URL')
export const updateStagedUrl = createAction(UPDATE_STAGED_URL.ACTION)

export const RESET_STAGED_URL = appSubmission.defineAction('RESET_STAGED_URL')
export const resetStagedUrl = createAction(RESET_STAGED_URL.ACTION)

export const ADD_APP_URL = appSubmission.defineAction('ADD_APP_URL')
export const addAppUrl = createAction(ADD_APP_URL.ACTION)

export const REMOVE_APP_URL = appSubmission.defineAction('REMOVE_APP_URL')
export const removeAppUrl = createAction(REMOVE_APP_URL.ACTION)

export const CANCEL_SAGA = appSubmission.defineAction('CANCEL_SAGA')
export const cancelSaga = createAction(CANCEL_SAGA.ACTION)
