import _ from 'lodash-es'
import {
  c11n,
  serverUrlFor,
  featureOn,
  stringifyQuery,
  urlJoin,
} from '../lib/utils.js'
import lkt from '../lkt.js'
import {getEntId} from './tenancy-helper.js'
import supportURLs from './support-urls.yml'

const zendeskURL = keyPath => {
  const return_to = _.get(supportURLs.zendesk, keyPath)
  const part = stringifyQuery({return_to})
  const query = (return_to && `?${part}`) || ''
  return serverUrlFor(
    urlJoin(lkt.config.support_url, getEntId(), `/sso/zendesk${query}`),
  )
}

export const supportUrl = keyPath =>
  featureOn('l4e_sfdc_support_portal')
    ? _.get(supportURLs.sfdc, keyPath)
    : zendeskURL(keyPath)

export const customerSupportUrl = () =>
  c11n('support.contact') || supportUrl('portal_link')
