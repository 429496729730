import I18n from '../i18n.js'
import Application from '../application.coffee'
import routes from '../routes.js'
import {appUrlRoot, urlJoin} from './utils.js'

/*
 * Instantiates an application by given type and routes.
 * @param  {Object} application       Normally it's a Chaplin.Application.
 * @param  {Function} routes          Custom routes.
 */
export const createApp = (TargetApplication = Application) =>
  new TargetApplication({
    title: I18n.t('brand.name'),
    controllerSuffix: '-controller',
    root: urlJoin(appUrlRoot(), '/'),
    routes,
    // chaplin has a reversed boolean meaning of the func result https://github.com/chaplinjs/chaplin/blob/master/src/chaplin/views/layout.coffee#L127
    // if it's a function it rather should be named shouldNotSkipRouting, the code
    // should be read accordingly
    skipRouting: (href, el) =>
      !el.classList.contains('noscript') &&
      (el.classList.contains('chaplin-link') ||
        !el.closest('.skip-chaplin-routing')),
    scrollTo: null, // we use in-house scroll in Controller.beforeAction
  })
