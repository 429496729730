import {defineAction} from 'redux-define'
import {createAction} from 'redux-actions'
import {
  defineFetchSubactions,
  createFetchSubactions,
} from './fetch-subactions-helper.js'

const gds = defineAction('gds')
const securityCloud = gds.defineAction('security-cloud')
const issueTrends = gds.defineAction('issue-trends')
const appAnalysis = gds.defineAction('app-analysis')
const appDetails = gds.defineAction('app-details')

export const READ_GLOBAL_PROTECTED_DEVICES = defineFetchSubactions(
  securityCloud.defineAction('global-protected-devices').defineAction('read'),
)
export const readGlobalProtectedDevices = createFetchSubactions(
  READ_GLOBAL_PROTECTED_DEVICES,
)

export const READ_FLEET_ANALYZED_APPS = defineFetchSubactions(
  securityCloud.defineAction('fleet-analyzed-apps').defineAction('read'),
)
export const readFleetAnalyzedApps = createFetchSubactions(
  READ_FLEET_ANALYZED_APPS,
)

export const READ_GLOBAL_ANALYZED_APPS = defineFetchSubactions(
  securityCloud.defineAction('global-analyzed-apps').defineAction('read'),
)
export const readGlobalAnalyzedApps = createFetchSubactions(
  READ_GLOBAL_ANALYZED_APPS,
)

export const READ_MULTISERIES = defineFetchSubactions(
  issueTrends.defineAction('multiseries').defineAction('read'),
)

export const readMultiseries = createFetchSubactions(READ_MULTISERIES)

export const READ_TOP_ISSUES = defineFetchSubactions(
  issueTrends.defineAction('top-issues').defineAction('read'),
)

export const readTopIssues = createFetchSubactions(READ_TOP_ISSUES)

export const FETCH_GLOBAL_CATEGORIES = defineFetchSubactions(
  appAnalysis.defineAction('categories'),
)
export const fetchGlobalCategories = createAction(
  FETCH_GLOBAL_CATEGORIES.REQUEST,
)

export const FETCH_GLOBAL_SUBCATEGORIES = defineFetchSubactions(
  appAnalysis.defineAction('subcategories'),
)
export const fetchGlobalSubcategories = createAction(
  FETCH_GLOBAL_SUBCATEGORIES.REQUEST,
)

export const FETCH_APP_PROPERTIES = defineFetchSubactions(
  appDetails.defineAction('app-properties'),
)

export const fetchAppProperties = createAction(FETCH_APP_PROPERTIES.REQUEST)
